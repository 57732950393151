import React from 'react';

export const GET_BANKS = 'GET_BANKS';
export const GET_DATABASE = 'GET_DATABASE';
export const GET_ROWS_COLUMNS_BY_TABLES = 'GET_ROWS_COLUMNS_BY_TABLES';
export const GET_COLUMNS = 'GET_COLUMNS';
export const GET_TABLES = 'GET_TABLES';
export const GET_CHARTS = 'GET_CHARTS';
export const OPEN_OPERATIONS_MENU = 'OPEN_OPERATIONS_MENU';
export const OPEN_PRINT_DIALOG = 'OPEN_PRINT_DIALOG';
export const OPEN_DATAPOINTS = 'OPEN_DATAPOINTS';
export const SET_TREE_DATA = 'SET_TREE_DATA';
export const SET_SELECTED_BANKS = 'SET_SELECTED_BANKS';
export const UNSET_SELECTED_BANKS = 'UNSET_SELECTED_BANKS';
export const UPDATE_KEYS = 'UPDATE_KEYS';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const UPDATE_DATABASE = 'UPDATE_DATABASE';
export const UPDATE_DATAPOINTS = 'UPDATE_DATAPOINTS';
export const UNSET_DATAPOINTS = 'UNSET_DATAPOINTS';
export const CLEAR_SELECTED_DATA = 'CLEAR_SELECTED_DATA';

export const SIDE_BAR = {
  databaseHeadingText: 'select database',
  banksHeadingText: 'select banks',
  periodHeadingText: 'select period',
  datapointsHeadingText: 'select operations',
  resetButtonText: 'reset',
  generateButtonText: 'generate output',
  newDataButtonText: 'add new data series',
  banksSelectedText: 'Banks Selected',
  seriesSelectedText: 'Series Selected',
  operationsHeadingText: 'Select data',
  tableColumns: [
    {
      title: 'Table',
      dataIndex: 'table',
      key: 'table',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}</p>,
    },
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}.</p>,
    },
    {
      title: 'Column',
      dataIndex: 'column',
      key: 'column',
      render: (text) => <p style={{ padding: '0 5px', marginBottom: 0 }}>{text}</p>,
    },
  ],
  operationsRefreshButtonText: 'Reset',
  operationsAddButtonText: 'ADD',
  points: {
    TARGET_TABLE: 'Nominal total amount (Rbn)',
    MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: 'Market total and as % of total assets',
    PERCENT_OF_ASSETS_REF_TABLE: 'Total as % of assets',
    MARKET_SHARE_REF: 'By market share %',
  },
  intialTrigger: {
    TARGET_TABLE: false,
    MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: false,
    PERCENT_OF_ASSETS_REF_TABLE: false,
    MARKET_SHARE_REF: false,
  },
  defaultBanks: [
    'all banks',
    'all others then those selected',
    'big 4',
    'big 5',
    'big 6',
    'd-sibs',
    'foreign controlled banks',
    'foreign bank branches',
    'locally controlled banks',
  ],
};

export default SIDE_BAR;
