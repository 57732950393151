import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getUsers = asyncActionCreator(constants.GET_USERS);
export const getUserById = asyncActionCreator(constants.GET_USER_BY_ID);
export const addUser = asyncActionCreator(constants.ADD_USER);
export const updateUserById = asyncActionCreator(constants.UPDATE_USER_BY_ID);
export const updateUserPasswordById = asyncActionCreator(constants.UPDATE_USER_PASSWORD_BY_ID);
export const deleteUserById = asyncActionCreator(constants.DELETE_USER_BY_ID);

export const clearSelectedUser = createAction(constants.CLEAR_SELECTED_USER);
export const clearSuccessStatus = createAction(constants.CLEAR_SUCCESS_STATUS);

export default getUsers;
