/* eslint-disable no-debugger, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerCreator } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
  addUserPage: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
};

const reducer = createReducer(initialState, {
  ...asyncReducerCreator(actions.getUsers, null, null, (state, action) => {
    state.loading = false;
    state.data = action.payload;
  }),
  ...asyncReducerCreator(actions.getUserById, 'addUserPage', null, (state, action) => {
    state.loading = false;
    state.data = action.payload;
  }),
  ...asyncReducerCreator(
    [actions.addUser, actions.updateUserById, actions.updateUserPasswordById],
    null,
    (state) => {
      state.addUserPage.loading = true;
      state.addUserPage.error = null;
      state.addUserPage.success = false;
    },
    (state) => {
      state.addUserPage.loading = false;
      state.addUserPage.success = true;
    },
    (state, action) => {
      state.addUserPage.loading = false;
      state.addUserPage.error = action.payload;
    },
  ),
  ...asyncReducerCreator(
    actions.deleteUserById,
    null,
    (state) => {
      state.loading = true;
      state.error = null;
    },
    (state, action) => {
      state.data = state.data.filter((user) => user.user_id !== action.payload);
      state.loading = false;
    },
  ),
  [actions.clearSelectedUser]: (state) => {
    state.addUserPage.data = null;
  },
  [actions.clearSuccessStatus]: (state) => {
    state.addUserPage.success = false;
  },
});

const usersPage = (state) => ({
  loading: state.usersReducer.loading,
  success: state.usersReducer.success,
  error: state.usersReducer.error,
  data: state.usersReducer.data,
});

const addUserPage = (state) => ({
  loading: state.usersReducer.addUserPage.loading,
  success: state.usersReducer.addUserPage.success,
  error: state.usersReducer.addUserPage.error,
  data: state.usersReducer.addUserPage.data,
});

const selectors = {
  usersPage,
  addUserPage,
};

export { reducer, initialState, selectors };

export default reducer;
