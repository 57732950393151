export const HEADER = {
  title: 'Feedback & Support:',
  email: 'Email: ',
  emailData: 'support@intellidex.co.za',
  phone: 'Tel: ',
  phoneData: '+27 10 072 0472',
  controls: {
    user: {
      id: 'user_management',
      name: 'User management',
      link: '/users/',
    },
    logout: {
      id: 'logout',
      name: 'Logout',
    },
    guide: {
      id: 'guide',
      name: 'User Guide',
    },
  },
};

export default HEADER;
