export const POST_LOGIN_USER = 'POST_LOGIN_USER';
export const POST_REFRESH_USER = 'POST_REFRESH_USER';
export const LOGOUT = 'LOGOUT';

export const LOGIN_PAGE = {
  loginForm: {
    placeholder: {
      username: 'Username',
      password: 'Password',
    },
    submitButtonText: 'LOGIN',
  },
  troubleText: 'Trouble logging in?',
  email: 'support@intellidex.co.za',
  phone: '+27 10 072 0472',
  footerQuestion: 'Interested in the BankAnalyser product?',
  click: 'Click',
  here: 'here',
  textEnd: 'for details and to sign up for a trial',
};

export default LOGIN_PAGE;
