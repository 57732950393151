export const ADD_USER_PAGE = {
  title: {
    add: 'Add User',
  },
  buttons: {
    addUser: 'ADD',
    editUser: 'SAVE',
    changePassword: 'CHANGE',
  },
  form: {
    labels: {
      id: 'ID',
      name: 'NAME',
      company: 'COMPANY',
      email: 'EMAIL',
      phone: 'PHONE',
      password: 'PASSWORD',
      repeatPassword: 'REPEAT PASSWORD',
    },
  },
};

export default ADD_USER_PAGE;
