import styled from 'styled-components';

export const ContentWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;

  .content__wrapper {
    flex: ${(props) => (props.isUsersPage ? '0 1 100%' : '0 1 calc(100% - 330px)')};
    min-width: ${(props) => (props.isUsersPage ? '100%' : 'calc(100% - 330px)')};
  }
`;

export default ContentWrapper;
