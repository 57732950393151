import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import InitComponent from './containers/InitComponent';

import theme from './theme';
import './App.less';
import './global/styles/index.scss';

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <InitComponent />
      </Switch>
    </Router>
  </ThemeProvider>
);

export default App;
