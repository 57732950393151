import { call, put, all, takeLatest } from 'redux-saga/effects';
import LoginService from './service';
import * as actions from './actions';
import * as constants from './constants';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';
import { writeToLocalStorage, clearLocalStorage } from '../../global/helpers';

export function* postLoginUserSaga({ payload }) {
  try {
    const response = yield call(LoginService.postLoginUser, payload);
    yield put(actions.postLoginUser.success(response.data));
    yield call(writeToLocalStorage, 'refreshToken', response.data.refresh);
    yield call(writeToLocalStorage, 'accessToken', response.data.access);
    yield call(writeToLocalStorage, 'userRole', response.data.role);
    yield call(writeToLocalStorage, 'userName', response.data.name);
  } catch (err) {
    yield put(actions.postLoginUser.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Authentication Failed',
      description: err.response?.data?.detail || err.message,
    });
    yield call(clearLocalStorage);
  }
}

export function* postRefreshUserSaga({ payload }) {
  try {
    const response = yield call(LoginService.postRefreshUser, payload);
    yield put(actions.postRefreshUser.success(response.data));
    yield call(writeToLocalStorage, 'refreshToken', response.data.refresh);
    yield call(writeToLocalStorage, 'accessToken', response.data.access);
    if (response.data.access) {
      yield call(writeToLocalStorage, 'userRole', response.data.role);
      yield call(writeToLocalStorage, 'userName', response.data.name);
    }
  } catch (err) {
    yield put(actions.postRefreshUser.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Authorization Failed',
      description: err.response?.data?.detail || err.message,
    });
    yield call(clearLocalStorage);
  }
}

export function* logoutSaga() {
  yield call(clearLocalStorage);
}

export function* loginPageWatcherSaga() {
  yield all([
    takeLatest(actions.postLoginUser.REQUEST, postLoginUserSaga),
    takeLatest(actions.postRefreshUser.REQUEST, postRefreshUserSaga),
    takeLatest(constants.LOGOUT, logoutSaga),
  ]);
}
