/* eslint-disable no-debugger, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerCreator } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  loading: false,
  databases: [],
  banks: [],
  tables: [],
  columns: [],
  selectedBanks: [],
  selectedDatabases: [],
  selectedDatapoints: null,
  triggers: [],
  charts: null,
  chartsLoading: false,
  date: null,
  keys: [],
  operationsMenuOpen: false,
  printDialogOpen: false,
  datapointsOpen: false,
  treeData: null,
  error: false,
};

const reducer = createReducer(initialState, {
  ...asyncReducerCreator(
    actions.getDatabase,
    null,
    (state) => {
      state.loading = true;
      state.databases = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.databases = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getBanks,
    null,
    (state) => {
      state.loading = true;
      state.banks = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.banks = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getTables,
    null,
    (state) => {
      state.loading = true;
      state.tables = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.tables = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getColumns,
    null,
    (state) => {
      state.loading = true;
      state.columns = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.columns = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getCharts,
    null,
    (state) => {
      state.chartsLoading = true;
      state.charts = null;
      state.error = false;
      state.generationStatus = true;
    },
    (state, action) => {
      state.chartsLoading = false;
      state.charts = action.payload;
      state.error = false;
    },
    (state, action) => {
      state.chartsLoading = false;
      state.error = action.payload;
    },
  ),
  [actions.openOperationsMenu]: (state, action) => {
    state.operationsMenuOpen = action.payload;
  },
  [actions.openPrintDialog]: (state, action) => {
    state.printDialogOpen = action.payload;
  },
  [actions.openDatapoints]: (state, action) => {
    state.datapointsOpen = action.payload;
  },
  [actions.setTreeData]: (state, action) => {
    state.treeData = action.payload;
  },
  [actions.setSelectedBanks]: (state, action) => {
    state.selectedBanks = [...state.selectedBanks, action.payload];
  },
  [actions.unsetSelectedBanks]: (state, action) => {
    state.selectedBanks = state.selectedBanks.filter((bank) => bank !== action.payload);
  },
  [actions.updateKeys]: (state, action) => {
    state.keys = action.payload;
  },
  [actions.updateDataBase]: (state, action) => {
    state.selectedDatabases = action.payload;
  },
  [actions.updatePeriod]: (state, action) => {
    state.date = action.payload;
  },
  [actions.updateDataPoints]: (state, action) => {
    state.triggers = [...state.triggers, action.payload];
    state.selectedDatapoints = { ...state.selectedDatapoints, [action.payload]: true };
  },
  [actions.unsetDataPoints]: (state, action) => {
    state.triggers = state.triggers.filter((triger) => triger !== action.payload);
    state.selectedDatapoints = { ...state.selectedDatapoints, [action.payload]: false };
  },
  [actions.clearSelectedData]: (state) => {
    state.loading = false;
    state.selectedBanks = [];
    state.selectedDatabases = [];
    state.keys = [];
    state.selectedDatapoints = null;
    state.triggers = [];
    state.date = null;
    state.charts = null;
  },
});

const databases = (state) => state.sideBarReducer.databases;
const banks = (state) => state.sideBarReducer.banks;
const tables = (state) => state.sideBarReducer.tables;
const rows = (state) => state.sideBarReducer.rows;
const columns = (state) => state.sideBarReducer.columns;
const operationsMenuOpen = (state) => state.sideBarReducer.operationsMenuOpen;
const printDialogOpen = (state) => state.sideBarReducer.printDialogOpen;
const datapointsOpen = (state) => state.sideBarReducer.datapointsOpen;
const treeData = (state) => state.sideBarReducer.treeData;
const selectedBanks = (state) => state.sideBarReducer.selectedBanks;
const selectedDatabases = (state) => state.sideBarReducer.selectedDatabases;
const targetKeys = (state) => state.sideBarReducer.keys;
const period = (state) => state.sideBarReducer.date;
const selectedDatapoints = (state) => state.sideBarReducer.selectedDatapoints;
const triggers = (state) => state.sideBarReducer.triggers;
const charts = (state) => state.sideBarReducer.charts;
const loading = (state) => state.sideBarReducer.chartsLoading;

export const selectors = {
  databases,
  banks,
  tables,
  operationsMenuOpen,
  printDialogOpen,
  datapointsOpen,
  treeData,
  rows,
  columns,
  selectedBanks,
  selectedDatabases,
  targetKeys,
  period,
  selectedDatapoints,
  triggers,
  charts,
  loading,
};

export default reducer;
