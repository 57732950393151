import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export const AddUserPageWrapper = styled('div')`
  width: 100%;
  padding: 15px 10%;
`;

export const AddUserPageHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .controls__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .page__title {
      font-size: 32px;
      line-height: 39px;
      color: #5a5a5a;
      font-weight: 700;
    }
  }

  button {
    width: 190px;
  }
`;

export const AddPageFormWrapper = styled('div')`
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  padding: 30px 50px;
  position: relative;
`;

export const DeviderRow = styled('div')`
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ccc;
  margin: 20px 0;
`;

export const FormWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const FormInputWrapper = styled('div')`
  flex: 0 1 33.33%;
  min-width: 320px;
  padding: 0 20px;
  margin-bottom: 20px;

  div {
    width: 100%;
    height: 40px;

    label {
      transform: translate(14px, 13px) scale(1);
    }
  }
`;

export const ChangePasswordButton = styled(Button)`
  background-color: #849fb6;
  color: #fff;
  width: 120px;
  height: 40px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

export const SaveButton = styled(Button)`
  position: absolute;
  top: -47px;
  right: 0;
  width: 190px;
`;

export const GoBackButton = styled(Link)`
  padding: 5px;
  border: 2px solid #849fb6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 15px;

  span {
    color: #849fb6;
  }
`;
