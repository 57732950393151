import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const PrintDialogWrapper = styled(Modal)`
  top: 30px;
  background: #a09e9e;
`;

export const PrintComponentWrapper = styled('div')`
  position: relative;
  display: block;
  width: 1000px;
  height: 1441px;
  padding: 20px;
  margin: 0 auto;
`;

export const PrintComponentHeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-aling: right;
  color: #f0542b;

  div {
    & h1 {
      color: #f0542b;
      font-weight: 400;
      font-size: 18px;
    }

    & div {
      font-size: 17px;
      font-weight: 700;
      text-align: end;
    }
  }
`;

export const PrintComponentHeaderWrapper = styled('div')`
  color: black;
  border: 1px solid #bdbdbd;
  text-align: left;
  width: 100%;
  padding: 10px 10px 0 10px;
  margin-top: 20px;

  & span {
    font-weight: 700;
    color: #8c8c8c;
  }

  p {
    color: #a6a6a6;
  }

  table {
    tr {
      th {
        background-color: white;
        padding: 0 0 5px 0;
        color: #8c8c8c;
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 0;
          color: #a6a6a6;
        }
      }
    }
  }
`;

export const PrintButton = styled(Button)`
  width: 80px;
  height: 40px;
  position: absolute;
  top: 12px;
  right: 60px;
`;

export const ChartsWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px;
`;

export const FooterWrapper = styled('div')`
  color: black;
  width: 100%;
  text-align: left;

  .company-title {
    color: #838383;
  }

  .source {
    color: #bbbfbd;
  }

  .link {
    color: #bbbfbd;
  }
`;

export default PrintDialogWrapper;
