import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import bgImage from '../../global/images/background.png';
import blurTop from '../../global/images/blure_top.png';
import blurBottom from '../../global/images/blure_bottom.png';

export const LoginPageWrapper = styled('div')`
  width: 100%;
  min-height: 100vh;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  .background__additional_top,
  .background__additional_bottom {
    display: block;
    width: 100%;
    height: 50vh;
    background-image: url(${blurTop});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .background__additional_bottom {
    background-image: url(${blurBottom});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: unset;
    bottom: 0;
  }
`;

export const ContentWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .application__header {
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const LoginFormWrapper = styled('div')`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
`;

export const LoginFormLogo = styled('div')`
  margin-bottom: 20px;
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-form-item:nth-child(2) {
    margin-bottom: 25px;
  }
`;

export const LoginFormInput = styled(Input)`
  width: 320px;

  &::placeholder {
    color: #58585a;
  }
`;

export const LoginFormButton = styled(Button)`
  width: 190px;
`;

export const LoginFormTrouble = styled('div')`
  margin-top: 60px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  span {
    color: #18232c;
    font-weight: 700;
    display: block;

    &:last-child {
      display: flex;
      font-weight: 600;

      a {
        margin: 0 5px;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }

  a {
    font-weight: 600;
    display: block;

    &:nth-child(3) {
      margin-bottom: 15px;
    }
  }
`;

export default LoginPageWrapper;
