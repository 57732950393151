import ApiService from '../../global/service/api';

export class SideBarService extends ApiService {
  getDatabases = async () => this.get({ url: 'databases/' });

  getBanks = async () => this.get({ url: 'banks/' });

  getTables = async () => this.get({ url: 'tables/' });

  getCharts = async (data) => this.post({ url: 'charts/', body: data });

  getRowsByTable = async (id) => {
    const response = await this.get({ url: `rowsColumnsByTable/${id}/` });
    return { row: response.data[0].rows, column: response.data[0].columns };
  };

  getColumns = async () => this.get({ url: `rowsColumnsByTable/1/` });
}

export default new SideBarService();
