import styled from 'styled-components';
import { Accordion } from '@material-ui/core';
import { Table } from 'antd';

export const AccordionWrapper = styled(Accordion)`
  top: 10px;
  width: 98%;
  margin: 0 auto !important;
`;

export const SeriesSelectedTable = styled(Table)`
  width: 100%;
`;

export default AccordionWrapper;
