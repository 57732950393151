import { combineReducers } from 'redux';
import { reducer as loginReducer } from './containers/LoginPage';
import { reducer as usersReducer } from './containers/UsersPage';
import { reducer as sideBarReducer } from './components/SideBar';

export default combineReducers({
  loginReducer,
  usersReducer,
  sideBarReducer,
});
