/* eslint-disable no-underscore-dangle, no-param-reassign */

import axios from 'axios';
import HttpService from './http';

export default class ApiService extends HttpService {
  _DEFAULT_OPTIONS = {
    url: '',
    body: null,
    headers: {},
    base_api_url: 'https://intellidex.dataforest.tech/api/',
  };

  _attachAuthHeaders = (options) => {
    options.headers = {
      ...options.headers,
      'Content-Type': 'application/json; charset=UTF-8',
    };
  };

  get = (options = {}, withAuth = true) => {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    return axios.get(`${newOptions.base_api_url}${newOptions.url}`, {
      headers: newOptions.headers,
    });
  };

  post = (options = {}, withAuth = true) => {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    return axios.post(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
    });
  };

  patch = (options = {}, withAuth = true) => {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    return axios.patch(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
    });
  };

  put = (options = {}, withAuth = true) => {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    return axios.put(`${newOptions.base_api_url}${newOptions.url}`, newOptions.body, {
      headers: newOptions.headers,
    });
  };

  delete = (options = {}, withAuth = true) => {
    const newOptions = { ...this._DEFAULT_OPTIONS, ...options };

    if (withAuth) {
      this._attachAuthHeaders(newOptions);
    }

    return axios.delete(`${newOptions.base_api_url}${newOptions.url}`, {
      headers: newOptions.headers,
    });
  };
}
