import ApiService from '../../global/service/api';

export class UsersService extends ApiService {
  getUsers = async () => this.get({ url: 'users/' });

  getUserById = async (id) => this.get({ url: `users/${id}` });

  adduser = async (data) => this.post({ url: 'users/', body: data });

  updateUserById = async (id, data) => this.put({ url: `users/${id}/`, body: data });

  updateUserPassword = async (id, data) =>
    this.patch({ url: `users/change-password/${id}`, body: data });

  deleteUser = async (id) => this.delete({ url: `users/${id}` });
}

export default new UsersService();
