export const PRINT_DIALOG = {
  printButtonText: 'Print',
  resultHeaderText: 'Results reports',
  showing: 'Showing:',
  dbHeader: 'Database: ',
  companyTitle: '© Intellidex UK Limited',
  sourceText: 'Source: SA Reserve Bank',
  website: 'www.intellidex.co.uk',
};

export default PRINT_DIALOG;
