import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SaveOutlined, PrinterOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Line, Bar } from 'react-chartjs-2';
import * as Styled from '../Charts.styles';
import CHART_SETTINGS from '../constants';
import SIDE_BAR from '../../SideBar/constants';
import { selectors } from '../../SideBar/reducer';
import PrintDialog from '../../PrintDialog';
import SeriesSelectedTable from '../../SeriesSelectedTable';
import { openPrintDialog } from '../../SideBar/actions';
import { getSelectedPairs, getValuesFromKeys } from '../../../global/helpers';

const BigChart = ({ match, history }) => {
  const chart = match.params.id;

  const dispatch = useDispatch();

  const charts = useSelector(selectors.charts);
  const targetKeys = useSelector(selectors.targetKeys);
  const tables = useSelector(selectors.tables);
  const columns = useSelector(selectors.columns);
  const treeData = useSelector(selectors.treeData);

  const getTableData = () => {
    if (tables && columns) {
      const rows = tables
        .reduce((accum, table) => [...accum, table.items.row], [])
        .flat()
        .reduce(
          (accum, row) => ({
            ...accum,
            [row.rownumber]: `${row?.rownumber} - ${row?.itemdescription}`,
          }),
          {},
        );
      const values = getValuesFromKeys(targetKeys);
      const selectedPairs = getSelectedPairs(treeData, values, columns);
      const tableData = [];
      Object.entries(selectedPairs).forEach((pair) => {
        const pairTableAndRow = pair[0].split('-');
        tableData.push({
          column: pair[1].join(', '),
          row: rows[pairTableAndRow[1]],
          table: `${pairTableAndRow[0]}: ${tables[pairTableAndRow[0] - 1]?.itemdescription}`,
        });
      });
      return tableData;
    }
    return null;
  };

  const title = SIDE_BAR.points[chart];

  const chartRef = useRef();

  const mapChartsData = () => {
    if (charts) {
      const { colors } = CHART_SETTINGS;
      const subject = Object.entries(charts).filter((item) => item[0] === chart)[0];
      if (subject) {
        const value = subject[1];
        const datasets =
          chart === 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ||
          chart === 'PERCENT_OF_ASSETS_REF_TABLE'
            ? value
                .filter((item) => !item.xlsx)
                .sort((item) => (item.type === 'line' ? -1 : 1))
                .map((item, index) => ({
                  label: item.label,
                  data: item.data,
                  fill: false,
                  backgroundColor: colors[index],
                  borderColor: colors[index],
                  type: item.type,
                  yAxisID: item.type === 'line' ? 'y1' : 'y',
                }))
            : value
                .filter((item) => !item.xlsx)
                .map((item, index) => ({
                  label: item.label,
                  data: item.data,
                  fill: false,
                  backgroundColor: colors[index],
                  borderColor: colors[index],
                  type: item.type,
                }));

        const data = {
          labels: Object.entries(charts)[0][1].map(
            (label) => `${label.slice(5, 7)}/${label.slice(2, 4)}`,
          ),
          datasets,
          download: value[value.length - 1].xlsx,
        };
        return data;
      }
      history.push('/');
      return null;
    }
    return null;
  };

  const value = charts ? Object.entries(charts)?.filter((item) => item[0] === chart)[0][1] : null;
  const scales =
    chart === 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ||
    chart === 'PERCENT_OF_ASSETS_REF_TABLE'
      ? {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              callback: (tickValue) => `${tickValue}%`,
            },
          },
        }
      : null;
  const getPrintContent = () => (
    <Styled.ChartPrintContentWrapper ref={chartRef}>
      <Styled.ChartHeader>
        <Styled.ChartTitle>{title}</Styled.ChartTitle>
      </Styled.ChartHeader>
      {chart !== 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ||
      chart === 'PERCENT_OF_ASSETS_REF_TABLE' ? (
        <Line data={mapChartsData()} options={{ ...CHART_SETTINGS.options }} />
      ) : (
        <Bar data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
      )}
    </Styled.ChartPrintContentWrapper>
  );

  return (
    <>
      {charts && targetKeys && <SeriesSelectedTable data={getTableData()} />}
      {charts && (
        <Styled.ChartsBlockWrapperBig>
          <Styled.ChartContentWrapper ref={chartRef}>
            <Styled.ChartHeader>
              <Styled.ChartTitle>{title}</Styled.ChartTitle>
              <div className="controls__wrapper">
                <a href={value[value.length - 1].xlsx}>
                  <Styled.ChartControl>
                    <SaveOutlined />
                  </Styled.ChartControl>
                </a>
                <Styled.ChartControl onClick={() => dispatch(openPrintDialog(true))}>
                  <PrinterOutlined />
                </Styled.ChartControl>
              </div>
            </Styled.ChartHeader>
            {chart !== 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ? (
              <Line data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
            ) : (
              <Bar data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
            )}
          </Styled.ChartContentWrapper>
        </Styled.ChartsBlockWrapperBig>
      )}
      {charts && (
        <Styled.GraphsWrapper>
          {Object.entries(SIDE_BAR.points)
            .filter((item) => item[0] !== chart)
            .map((item) => (
              <Link to={`${item[0]}`} key={item[0]}>
                <Styled.GraphControls>{item[1]}</Styled.GraphControls>
              </Link>
            ))}
          <Styled.AllGraphsControl onClick={() => history.push('/')}>
            <AppstoreOutlined />
            All Graphs
          </Styled.AllGraphsControl>
        </Styled.GraphsWrapper>
      )}
      {charts && <PrintDialog componentToPrint={getPrintContent} />}
    </>
  );
};

BigChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default BigChart;
