import React from 'react';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ArrowDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import * as Styled from './SeriesSelectedTable.styles';
import { SIDE_BAR } from '../SideBar/constants';
import { SIRIES_SELECTED } from './constants';

const SeriesSelectedTable = ({ data }) => (
  <Styled.AccordionWrapper>
    <AccordionSummary expandIcon={<ArrowDownOutlined />}>
      <p>{SIRIES_SELECTED.header}</p>
    </AccordionSummary>
    <AccordionDetails>
      <Styled.SeriesSelectedTable
        dataSource={data}
        columns={SIDE_BAR.tableColumns}
        pagination={false}
      />
    </AccordionDetails>
  </Styled.AccordionWrapper>
);

SeriesSelectedTable.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.array,
};

SeriesSelectedTable.defaultProps = {
  data: null,
};

export default SeriesSelectedTable;
