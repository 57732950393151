import styled from 'styled-components';

export const LoaderWrapper = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #2121216b;
  z-index: 100;

  .ant-spin-dot {
    font-size: 52px;

    .ant-spin-dot-item {
      background-color: #fff;
      width: 24px !important;
      height: 24px !important;
    }
  }

  .ant-spin-text {
    font-size: 21px;
    color: #fff;
    font-weight: 600;
  }
`;

export default LoaderWrapper;
