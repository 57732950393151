import styled from 'styled-components';

export const ChartsBlockWrapper = styled('div')`
  max-width: 50%;
  flex: 0 1 50%;
  padding: 12px;
`;

export const ChartsBlockWrapperBig = styled('div')`
  width: 100%;
  max-width: 1296px;
  flex: 0 1 50%;
  padding: 21px 12px;
`;

export const ChartContentWrapper = styled('div')`
  background-color: #fff;
  padding: 10px;
  width: 100%;
`;

export const ChartPrintContentWrapper = styled('div')`
  background-color: #fff;
  padding: 10px;
  width: 100%;
  margin: 20px 0;
`;

export const ChartHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 25px;

  .controls__wrapper {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ChartTitle = styled('div')`
  width: 100%;
  text-align: left;
  color: #5a5a5a;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const ChartControl = styled('button')`
  width: 24px;
  height: 24px;
  border: 1px solid #849fb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  margin: 0 3px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #f1f6fc;
  }

  span {
    color: #849fb6;
  }
`;

export const GraphsWrapper = styled('div')`
  width: 100%;
  margin-left: 10px;
`;

export const GraphControls = styled('button')`
  border-radius: 3px;
  height: 54px;
  border: 1px solid #849fb6;
  text-transform: uppercase;
  margin-right: 15px;

  &:hover {
    background-color: #f1f6fc;
  }

  span {
    color: #849fb6;
  }
`;

export const AllGraphsControl = styled('button')`
  border-radius: 3px;
  width: 218px;
  height: 54px;
  color: #fff;
  background-color: #849fb6;
  text-transform: uppercase;

  &:hover {
    color: #849fb6;
    border: 1px solid #849fb6;
    background-color: #fff;
  }
`;

export default ChartsBlockWrapper;
