/* eslint-disable no-debugger, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { openPrintDialog } from '../SideBar/actions';

const initialState = {
  open: false,
};

const reducer = createReducer(initialState, {
  [openPrintDialog]: (state, action) => {
    state.open = action.payload;
  },
});

const open = (state) => state.printDialogReducer.open;

const selectors = {
  open,
};

export { selectors };

export default reducer;
