import { call, put, all, takeLatest } from 'redux-saga/effects';
import SideBarService from './service';
import * as actions from './actions';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

export function* getDatabaseSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getDatabases, payload);
    yield put(actions.getDatabase.success(response.data));
  } catch (err) {
    yield put(actions.getDatabase.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Databases Data Load Failed',
      description: err.message,
    });
  }
}

export function* getBanksSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getBanks, payload);
    yield put(actions.getBanks.success(response.data));
  } catch (err) {
    yield put(actions.getBanks.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Banks Data Load Failed',
      description: err.message,
    });
  }
}

export function* getTablesSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getTables, payload);
    const tablesWithErrors = [];
    if (response.data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const table of response.data) {
        try {
          [table.items] = yield all([call(SideBarService.getRowsByTable, table.tablenumber)]);
        } catch (err) {
          tablesWithErrors.push(table.itemdescription);
          yield call(openNotification, {
            type: NOTIFICATIONS_CONFIG.types.error,
            message: 'Table data loading error',
            description: `Can not load data for ${table.itemdescription}`,
          });
        }
      }
    }
    yield put(actions.getTables.success(response.data));
  } catch (err) {
    yield put(actions.getTables.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Tables Data Load Failed',
      description: err.message,
    });
  }
}

export function* getChartsSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getCharts, payload);
    yield put(actions.getCharts.success(response.data.result[0]));
  } catch (err) {
    yield put(actions.getCharts.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Charts data loading error',
      description: err.message,
    });
  }
}

export function* getColumnsSaga() {
  try {
    const response = yield call(SideBarService.getColumns);
    yield put(actions.getColumns.success(response.data[0].columns));
  } catch (err) {
    yield put(actions.getColumns.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Columns Loading Failed',
      description: err.message,
    });
  }
}

export function* sideBarSagaWatcher() {
  yield all([
    takeLatest(actions.getDatabase.REQUEST, getDatabaseSaga),
    takeLatest(actions.getBanks.REQUEST, getBanksSaga),
    takeLatest(actions.getTables.REQUEST, getTablesSaga),
    takeLatest(actions.getColumns.REQUEST, getColumnsSaga),
    takeLatest(actions.getCharts.REQUEST, getChartsSaga),
  ]);
}

export default sideBarSagaWatcher;
