import { call, put, all, takeLatest } from 'redux-saga/effects';
import UsersService from './service';
import * as actions from './actions';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

export function* getUsersSaga() {
  try {
    const response = yield call(UsersService.getUsers);
    yield put(actions.getUsers.success(response.data));
  } catch (err) {
    yield put(actions.getUsers.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Getting users list error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* getUserByIdSaga({ payload }) {
  try {
    const response = yield call(UsersService.getUserById, payload);
    yield put(actions.getUserById.success(response.data));
  } catch (err) {
    yield put(actions.getUserById.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Getting user error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* addUserSaga({ payload }) {
  try {
    const response = yield call(UsersService.adduser, payload);
    yield put(actions.addUser.success(response.data));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.success,
      message: 'User successfully added.',
    });
  } catch (err) {
    yield put(actions.addUser.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Adding user error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* updateUserByIdSaga({ payload }) {
  try {
    yield call(UsersService.updateUserById, payload.id, payload.data);
    yield put(actions.updateUserById.success());
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.success,
      message: 'User successfully updated.',
    });
  } catch (err) {
    yield put(actions.updateUserById.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Updating user error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* updateUserPasswordById({ payload }) {
  try {
    yield call(UsersService.updateUserPassword, payload.id, payload.data);
    yield put(actions.updateUserPasswordById.success());
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.success,
      message: 'User password successfully updated.',
    });
  } catch (err) {
    yield put(actions.updateUserPasswordById.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Updating user password error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* deleteUserByIdSaga({ payload }) {
  try {
    yield call(UsersService.deleteUser, payload);
    yield put(actions.deleteUserById.success(payload));
    yield put(actions.getUsers.request());
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.success,
      message: 'User successfully updated.',
    });
  } catch (err) {
    yield put(actions.deleteUserById.error(err.response?.data?.detail || err.message));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Deleting user error',
      description: err.response?.data?.detail || err.message,
    });
  }
}

export function* usersPageWatcherSaga() {
  yield all([
    takeLatest(actions.getUsers.REQUEST, getUsersSaga),
    takeLatest(actions.getUserById.REQUEST, getUserByIdSaga),
    takeLatest(actions.addUser.REQUEST, addUserSaga),
    takeLatest(actions.updateUserById.REQUEST, updateUserByIdSaga),
    takeLatest(actions.updateUserPasswordById.REQUEST, updateUserPasswordById),
    takeLatest(actions.deleteUserById.REQUEST, deleteUserByIdSaga),
  ]);
}
