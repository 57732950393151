import styled from 'styled-components';
import { Accordion } from '@material-ui/core';
import { Table } from 'antd';

export const DashboardPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px;
`;

export const AccordionWrapper = styled(Accordion)`
  top: 10px;
  max-width: 96%;
  margin: 0 auto !important;
`;

export const SeriesSelectedTable = styled(Table)`
  width: 100%;
`;

export default DashboardPageWrapper;
