export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';
export const UPDATE_USER_PASSWORD_BY_ID = 'UPDATE_USER_PASSWORD_BY_ID';
export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID';

export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const CLEAR_SUCCESS_STATUS = 'CLEAR_SUCCESS_STATUS';

export const USERS_PAGE = {
  title: 'Users',
  buttons: {
    newUser: 'NEW USER',
  },
  deleteModal: {
    title: 'Are you sure delete this user?',
  },
  table: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'user_id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '',
        dataIndex: '',
        key: 'actions',
      },
    ],
  },
};

export default USERS_PAGE;
