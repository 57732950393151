import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@material-ui/core';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as actions from '../../actions';
import { selectors } from '../../reducer';
import * as Styled from './AddUserPage.style';
import ADD_USER_PAGE from './constants';
import Loader from '../../../../components/Loader';
import {
  createUserValidationSchema,
  editUserValidationSchema,
  updateUserPasswordValidationSchema,
} from '../../validationSchemas';

const AddUserPage = ({ history, match }) => {
  const isEditUser = match.params.id;
  const dispatch = useDispatch();

  const { loading, data, success } = useSelector(selectors.addUserPage);

  const [prevSubmittedData, setPrevSubmittedData] = useState(null);

  useEffect(() => {
    if (isEditUser) {
      dispatch(actions.getUserById.request(match.params.id));
    }

    return () => {
      setPrevSubmittedData(null);
      dispatch(actions.clearSelectedUser());
    };
  }, [dispatch, isEditUser, match.params.id]);

  useEffect(() => {
    if (success) {
      dispatch(actions.clearSuccessStatus());
      history.push('/users');
    }
  }, [dispatch, history, success]);

  const handleSubmitUser = (values) => {
    setPrevSubmittedData(values);
    if (isEditUser) {
      dispatch(
        actions.updateUserById.request({
          id: data.id,
          data: {
            name: values.name,
            email: values.email,
            phone: values.phone,
            company: values.company,
            user_id: values.user_id,
          },
        }),
      );
    } else {
      dispatch(
        actions.addUser.request({
          user_id: values.user_id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          company: values.company,
          password: values.password,
          confirm_password: values.confirm_password,
        }),
      );
    }
  };

  const handleUpdateUserPassword = (values) => {
    dispatch(
      actions.updateUserPasswordById.request({
        id: data.id,
        data: {
          password: values.password,
        },
      }),
    );
  };

  return (
    <Styled.AddUserPageWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Styled.AddUserPageHeader>
            <div className="controls__wrapper">
              <Styled.GoBackButton to="/users/">
                <ArrowLeftOutlined />
              </Styled.GoBackButton>
              <div className="page__title">
                {!isEditUser ? ADD_USER_PAGE.title.add : data?.name}
              </div>
            </div>
          </Styled.AddUserPageHeader>
          <Styled.AddPageFormWrapper>
            <Formik
              initialValues={{
                user_id: prevSubmittedData?.user_id || data?.user_id || '',
                name: prevSubmittedData?.name || data?.name || '',
                company: prevSubmittedData?.company || data?.company || '',
                email: prevSubmittedData?.email || data?.email || '',
                phone: prevSubmittedData?.phone || data?.phone || '',
                password: '',
                confirm_password: '',
              }}
              validationSchema={isEditUser ? editUserValidationSchema : createUserValidationSchema}
              enableReinitialize
              onSubmit={handleSubmitUser}
            >
              {({ isValid, dirty }) => (
                <Form>
                  <Styled.SaveButton
                    type="primary"
                    htmlType="submit"
                    disabled={!(isValid && dirty)}
                  >
                    {!isEditUser ? ADD_USER_PAGE.buttons.addUser : ADD_USER_PAGE.buttons.editUser}
                  </Styled.SaveButton>
                  <Styled.FormWrapper>
                    {isEditUser && (
                      <Styled.FormInputWrapper>
                        <Field name="user_id">
                          {({ field }) => (
                            <TextField
                              disabled
                              label={ADD_USER_PAGE.form.labels.id}
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                              variant="outlined"
                            />
                          )}
                        </Field>
                      </Styled.FormInputWrapper>
                    )}
                    <Styled.FormInputWrapper>
                      <Field name="name">
                        {({ field }) => (
                          <TextField
                            label={ADD_USER_PAGE.form.labels.name}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Styled.FormInputWrapper>
                    <Styled.FormInputWrapper>
                      <Field name="company">
                        {({ field }) => (
                          <TextField
                            label={ADD_USER_PAGE.form.labels.company}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Styled.FormInputWrapper>
                    <Styled.FormInputWrapper>
                      <Field name="email">
                        {({ field }) => (
                          <TextField
                            label={ADD_USER_PAGE.form.labels.email}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Styled.FormInputWrapper>
                    <Styled.FormInputWrapper>
                      <Field name="phone">
                        {({ field }) => (
                          <TextField
                            label={ADD_USER_PAGE.form.labels.phone}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            variant="outlined"
                          />
                        )}
                      </Field>
                    </Styled.FormInputWrapper>
                  </Styled.FormWrapper>
                  {!isEditUser && (
                    <>
                      <Styled.DeviderRow />
                      <Styled.FormWrapper>
                        <Styled.FormInputWrapper>
                          <Field name="password">
                            {({ field }) => (
                              <TextField
                                type="password"
                                label={ADD_USER_PAGE.form.labels.password}
                                name={field.name}
                                onChange={field.onChange}
                                variant="outlined"
                              />
                            )}
                          </Field>
                        </Styled.FormInputWrapper>
                        <Styled.FormInputWrapper>
                          <Field name="confirm_password">
                            {({ field }) => (
                              <TextField
                                type="password"
                                label={ADD_USER_PAGE.form.labels.repeatPassword}
                                name={field.name}
                                onChange={field.onChange}
                                variant="outlined"
                              />
                            )}
                          </Field>
                        </Styled.FormInputWrapper>
                      </Styled.FormWrapper>
                    </>
                  )}
                </Form>
              )}
            </Formik>

            {isEditUser && (
              <>
                <Styled.DeviderRow />
                <Formik
                  initialValues={{
                    password: '',
                    confirm_password: '',
                  }}
                  validationSchema={updateUserPasswordValidationSchema}
                  onSubmit={handleUpdateUserPassword}
                >
                  {({ isValid, dirty }) => (
                    <Form>
                      <Styled.FormWrapper>
                        <Styled.FormInputWrapper>
                          <Field name="password">
                            {({ field }) => (
                              <TextField
                                type="password"
                                label={ADD_USER_PAGE.form.labels.password}
                                name={field.name}
                                onChange={field.onChange}
                                variant="outlined"
                              />
                            )}
                          </Field>
                        </Styled.FormInputWrapper>
                        <Styled.FormInputWrapper>
                          <Field name="confirm_password">
                            {({ field }) => (
                              <TextField
                                type="password"
                                label={ADD_USER_PAGE.form.labels.repeatPassword}
                                name={field.name}
                                onChange={field.onChange}
                                variant="outlined"
                              />
                            )}
                          </Field>
                        </Styled.FormInputWrapper>
                        <Styled.ChangePasswordButton
                          htmlType="submit"
                          disabled={!(isValid && dirty)}
                        >
                          {ADD_USER_PAGE.buttons.changePassword}
                        </Styled.ChangePasswordButton>
                      </Styled.FormWrapper>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Styled.AddPageFormWrapper>
        </>
      )}
    </Styled.AddUserPageWrapper>
  );
};

AddUserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddUserPage;
