/* eslint-disable no-debugger, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerCreator } from '../../global/redux';
import * as actions from './actions';
import { getFromLocalStorage } from '../../global/helpers';

const initialState = {
  loading: false,
  accessToken: getFromLocalStorage('accessToken'),
  refreshToken: getFromLocalStorage('refreshToken'),
  userRole: getFromLocalStorage('userRole'),
  error: null,
};

const reducer = createReducer(initialState, {
  ...asyncReducerCreator(
    [actions.postLoginUser, actions.postRefreshUser],
    null,
    (state) => {
      state.loading = true;
      state.accessToken = null;
      state.refreshToken = null;
      state.userRole = null;
    },
    (state, action) => {
      state.loading = false;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.userRole = action.payload.role;
    },
  ),
  [actions.logout]: (state) => {
    state.loading = false;
    state.accessToken = null;
    state.refreshToken = null;
    state.userRole = null;
    state = null;
  },
});

const loading = (state) => state.loginReducer.loading;
const accessToken = (state) => state.loginReducer.accessToken;
const refreshToken = (state) => state.loginReducer.refreshToken;
const userRole = (state) => state.loginReducer.userRole;

const selectors = {
  loading,
  accessToken,
  userRole,
  refreshToken,
};

export { reducer, initialState, selectors };

export default reducer;
