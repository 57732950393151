import { notification } from 'antd';

export const NOTIFICATIONS_CONFIG = {
  types: {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
  },
};

export const openNotification = ({ type, message, description }) => {
  notification[type]({
    message,
    description,
  });
};
