import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectors } from '../LoginPage/reducer';
import LoginPage from '../LoginPage';
import MainPage from '../MainPage';

export const InitComponent = () => {
  const isLogIn = useSelector(selectors.accessToken);

  const selectStartPage = () => {
    if (isLogIn) {
      return <MainPage />;
    }

    return (
      <>
        <Route exact path="/login" component={LoginPage} />
        <Redirect to="/login" />
      </>
    );
  };

  return selectStartPage();
};

export default InitComponent;
