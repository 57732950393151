import styled from 'styled-components';

export const HeaderWrapper = styled('div')`
  padding: ${(props) => (props.isLoginPage ? '20px 10%' : '20px 20px 20px 35px')};
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled('div')``;

export const Info = styled('div')`
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  .text__bold {
    color: #f0542b;
    font-weight: 900;
    font-size: 14px;
    display: block;
  }
  .text__info {
    color: #f0542b;
    font-size: 14px;
    display: block;

    a {
      color: #f0542b;
    }
  }
`;

export const InfoControls = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-right: -6px;

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f0542b;
    margin: 0 5px;
    font-weight: 700;
    border: none;
    outline: none;
    background-color: transparent;

    &:last-child {
      margin: 0 0 0 5px;
    }

    &:hover {
      text-decoration: underline;
    }

    &.active {
      color: #19192f;
    }
  }
`;

export default HeaderWrapper;
