/* eslint-disable no-debugger, no-console */

export default class HttpService {
  METHODS = {
    GET: 'Get',
    POST: 'Post',
    PUT: 'Put',
    PATCH: 'Patch',
    DELETE: 'Delete',
  };

  methodNotImplementedMessage = (type) => `${type} method isn't implemented`;

  get() {
    console.error(this.methodNotImplementedMessage(this.METHODS.GET));
    return this.methodNotImplementedMessage(this.METHODS.GET);
  }

  post() {
    console.error(this.methodNotImplementedMessage(this.METHODS.POST));
    return this.methodNotImplementedMessage(this.METHODS.POST);
  }

  put() {
    console.error(this.methodNotImplementedMessage(this.METHODS.PUT));
    return this.methodNotImplementedMessage(this.METHODS.PUT);
  }

  patch() {
    console.error(this.methodNotImplementedMessage(this.METHODS.PATCH));
    return this.methodNotImplementedMessage(this.METHODS.PATCH);
  }

  delete() {
    console.error(this.methodNotImplementedMessage(this.METHODS.DELETE));
    return this.methodNotImplementedMessage(this.METHODS.DELETE);
  }
}
