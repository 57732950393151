export const CHART_SETTINGS = {
  colors: [
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
    '#00CED1',
    '#000000',
    '#1E90FF',
    '#DB7093',
    '#8B4513',
    '#808080',
    '#DC143C',
    '#708090',
    '#800080',
    '#CD5C5C',
    '#B8860B',
    '#6A5ACD',
    '#556B2F',
    '#228B22',
    '#8FBC8F',
    '#800000',
    '#FF4500',
    '#2E8B57',
    '#4169E1',
    '#20B2AA',
    '#2F4F4F',
    '#4682B4',
    '#008080',
    '#5F9EA0',
    '#FF0000',
    '#B0C4DE',
    '#006400',
    '#483d8b',
  ],
  options: {
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
    },
  },
};

export default CHART_SETTINGS;
