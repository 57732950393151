import React from 'react';
import { Spin } from 'antd';
import * as Styled from './Loader.styles';

const Loader = () => (
  <Styled.LoaderWrapper>
    <Spin tip="Loading..." size="large" />
  </Styled.LoaderWrapper>
);

export default Loader;
