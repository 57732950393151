import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './DashboardPage.style';
import Charts from '../../components/Charts';
import { selectors } from '../../components/SideBar/reducer';
import SIDE_BAR from '../../components/SideBar/constants';
import Loader from '../../components/Loader';
import PrintDialog from '../../components/PrintDialog';
import SeriesSelectedTable from '../../components/SeriesSelectedTable';
import CHART_SETTINGS from '../../components/Charts/constants';
import { getSelectedPairs, getValuesFromKeys } from '../../global/helpers';

const DashboardPage = () => {
  const chartsRef = useRef();

  const chartsData = useSelector(selectors.charts);
  const loading = useSelector(selectors.loading);
  const datapoints = useSelector(selectors.selectedDatapoints);
  const targetKeys = useSelector(selectors.targetKeys);
  const tables = useSelector(selectors.tables);
  const columns = useSelector(selectors.columns);
  const treeData = useSelector(selectors.treeData);

  const getTableData = () => {
    if (tables && columns) {
      const rows = tables
        .reduce((accum, table) => [...accum, table.items.row], [])
        .flat()
        .reduce(
          (accum, row) => ({
            ...accum,
            [row.rownumber]: `${row?.rownumber} - ${row?.itemdescription}`,
          }),
          {},
        );
      const values = getValuesFromKeys(targetKeys);
      const selectedPairs = getSelectedPairs(treeData, values, columns);
      const tableData = [];
      Object.entries(selectedPairs).forEach((pair) => {
        const pairTableAndRow = pair[0].split('-');
        tableData.push({
          column: pair[1].join(', '),
          row: rows[pairTableAndRow[1]],
          table: `${pairTableAndRow[0]}: ${tables[pairTableAndRow[0] - 1]?.itemdescription}`,
        });
      });
      return tableData;
    }
    return null;
  };

  const renderCharts = () => {
    if (!datapoints || Object.entries(datapoints)) {
      const { colors } = CHART_SETTINGS;
      const entries = Object.entries(chartsData);
      const charts = entries.map(([key, value]) => {
        if (key !== 'labels' && value.length !== 0) {
          const chartTitle = SIDE_BAR.points[key];
          const datasets =
            chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS ||
            chartTitle === SIDE_BAR.points.PERCENT_OF_ASSETS_REF_TABLE
              ? value
                  .filter((item) => !item.xlsx)
                  .sort((item) => (item.type === 'line' ? -1 : 1))
                  .map((item, index) => ({
                    label: item.label,
                    data: item.data,
                    fill: false,
                    backgroundColor: colors[index],
                    borderColor: colors[index],
                    type: item.type,
                    yAxisID: item.type === 'line' ? 'y1' : 'y',
                  }))
              : value
                  .filter((item) => !item.xlsx)
                  .map((item, index) => ({
                    label: item.label,
                    data: item.data,
                    fill: false,
                    backgroundColor: colors[index],
                    borderColor: colors[index],
                    type: item.type,
                  }));
          const data = {
            labels: entries[0][1].map((label) => `${label.slice(5, 7)}/${label.slice(2, 4)}`),
            datasets,
          };
          const scales =
            chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS ||
            chartTitle === SIDE_BAR.points.PERCENT_OF_ASSETS_REF_TABLE
              ? {
                  y: {
                    type: 'linear',
                    display:
                      chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS,
                    position: 'left',
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                      callback: (tickValue) => `${tickValue}%`,
                    },
                  },
                }
              : null;

          const getOptions = (chartLabels) => {
            if (chartLabels.length > 21) {
              return {
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            }
            if (chartLabels.length < 21 && chartLabels.length > 6) {
              return {
                plugins: {
                  legend: {
                    labels: {
                      font: {
                        size: 8,
                      },
                    },
                  },
                },
              };
            }

            return {
              plugins: {
                legend: {
                  labels: {
                    font: {
                      size: 11,
                    },
                  },
                },
              },
            };
          };

          const download = { ...value[value.length - 1] };
          return (
            <Charts
              title={chartTitle}
              data={data}
              key={key}
              link={key}
              download={download.xlsx}
              scales={scales}
              options={getOptions(value)}
            />
          );
        }
        return null;
      });
      return charts;
    }
    return null;
  };

  return (
    <>
      {targetKeys && chartsData && <SeriesSelectedTable data={getTableData()} />}
      <Styled.DashboardPageWrapper ref={chartsRef}>
        {loading ? <Loader /> : null}
        {chartsData ? renderCharts() : null}
      </Styled.DashboardPageWrapper>
      {chartsData && <PrintDialog componentToPrint={renderCharts} />}
    </>
  );
};

export default DashboardPage;
