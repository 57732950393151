import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { logout, postRefreshUser } from '../../containers/LoginPage/actions';

const withAuth = (WrappedComponent) => {
  class ComposedComponent extends Component {
    requestInterceptor = axios.interceptors.request.use((req) => {
      const { accessToken } = this.props;
      req.headers.Authorization = `JWT ${accessToken}`;
      return req;
    });

    responseInterceptor = axios.interceptors.response.use(
      (res) => {
        const { logoutRequest, accessToken } = this.props;
        if (!accessToken) {
          return logoutRequest();
        }
        return res;
      },
      (err) => {
        const { postRefreshUserRequest, logoutRequest, refreshToken } = this.props;
        const { status } = err.response;

        if (status === 401 || status === 403) {
          if (refreshToken) {
            return postRefreshUserRequest({ refresh: refreshToken });
          }
          return logoutRequest();
        }
        return Promise.reject(err);
      },
    );

    componentWillUnmount() {
      axios.interceptors.response.eject(this.responseInterceptor);
      axios.interceptors.request.eject(this.requestInterceptor);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = {
    postRefreshUserRequest: postRefreshUser.request,
    logoutRequest: logout,
  };

  const mapStateToProps = (state) => ({
    refreshToken: state.loginReducer.refreshToken,
    accessToken: state.loginReducer.accessToken,
  });

  ComposedComponent.propTypes = {
    postRefreshUserRequest: PropType.func.isRequired,
    logoutRequest: PropType.func.isRequired,
    refreshToken: PropType.string,
    accessToken: PropType.string,
  };

  ComposedComponent.defaultProps = {
    refreshToken: null,
    accessToken: null,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withAuth;
