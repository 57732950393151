import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import * as actions from './actions';
import * as Styled from './LoginPage.styles';
import { selectors } from './reducer';
import logo from '../../global/images/logoAlpha.png';
import Header from '../../components/Header';
import LOGIN_PAGE from './constants';
import Loader from '../../components/Loader';

const LoginPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector(selectors.loading);

  const handleLoginUser = (data) => dispatch(actions.postLoginUser.request(data));

  return (
    <Styled.LoginPageWrapper>
      <div className="background__additional_top" />
      <Styled.ContentWrapper>
        <Header />
        {loading && <Loader />}
        <Styled.LoginFormWrapper>
          <Styled.LoginFormLogo>
            <img src={logo} alt="Intellidex Logo" />
          </Styled.LoginFormLogo>
          <Styled.LoginForm
            initialValues={{
              email: '',
              password: '',
            }}
            onFinish={handleLoginUser}
          >
            <Form.Item name="email">
              <Styled.LoginFormInput placeholder={LOGIN_PAGE.loginForm.placeholder.username} />
            </Form.Item>
            <Form.Item name="password">
              <Styled.LoginFormInput
                type="password"
                placeholder={LOGIN_PAGE.loginForm.placeholder.password}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Styled.LoginFormButton type="primary" htmlType="submit">
                {LOGIN_PAGE.loginForm.submitButtonText}
              </Styled.LoginFormButton>
            </Form.Item>
            <Styled.LoginFormTrouble>
              <span>{LOGIN_PAGE.troubleText}</span>
              <a href={`mailto: ${LOGIN_PAGE.email}`}>{LOGIN_PAGE.email}</a>
              <a href="tel:27100720472">{LOGIN_PAGE.phone}</a>
              <span>{LOGIN_PAGE.footerQuestion}</span>
              <span>
                {LOGIN_PAGE.click}{' '}
                <a href="https://www.intellidex.co.za/bankanalyser/">{LOGIN_PAGE.here}</a>{' '}
                {LOGIN_PAGE.textEnd}
              </span>
            </Styled.LoginFormTrouble>
          </Styled.LoginForm>
        </Styled.LoginFormWrapper>
      </Styled.ContentWrapper>
      <div className="background__additional_bottom" />
    </Styled.LoginPageWrapper>
  );
};

export default LoginPage;
