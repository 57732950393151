import * as yup from 'yup';

export const createUserValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  company: yup.string().required('Company is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup.string().required(),
  password: yup.string().min(6).required('Password is required'),
  confirm_password: yup
    .string()
    .min(6)
    .oneOf([yup.ref('password'), null], 'Password must match')
    .required('This field is required'),
});

export const editUserValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  company: yup.string().required('Company is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup.string().required(),
});

export const updateUserPasswordValidationSchema = yup.object().shape({
  password: yup.string().min(6).required('Password is required'),
  confirm_password: yup
    .string()
    .min(6)
    .oneOf([yup.ref('password'), null], 'Password must match')
    .required('This field is required'),
});

export default createUserValidationSchema;
