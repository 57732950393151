import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Styled from './SideBar.styles';
import * as actions from './actions';
import SIDE_BAR from './constants';
import { selectors } from './reducer';
import OperationsWindow from '../OperationsWindow';
import { getFormatedData, getValuesFromKeys } from '../../global/helpers';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

const DataSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const now = new Date();

  const operationsMenuOpen = useSelector(selectors.operationsMenuOpen);

  const addBank = (option) => dispatch(actions.setSelectedBanks(option));
  const removeBank = (option) => dispatch(actions.unsetSelectedBanks(option));
  const selectColumns = (keys) => dispatch(actions.updateKeys(keys));
  const setDatabase = (db) => dispatch(actions.updateDataBase(db));
  const setPeriod = (period) => dispatch(actions.updatePeriod(period));
  const clearData = () => {
    dispatch(actions.clearSelectedData());
    history.push('/');
  };
  const unsetDatapoints = (key) => {
    dispatch(actions.unsetDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };
  const setDatapoints = (key) => {
    dispatch(actions.updateDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };

  useEffect(() => {
    dispatch(actions.getDatabase.request());
    dispatch(actions.getBanks.request());
    dispatch(actions.getTables.request());
    dispatch(actions.getColumns.request());
  }, [dispatch]);

  const { Option } = Select;
  const banks = useSelector(selectors.banks);
  const databases = useSelector(selectors.databases);
  const selectedBanks = useSelector(selectors.selectedBanks);
  const selectedDatabases = useSelector(selectors.selectedDatabases);
  const selectedDatapoints = useSelector(selectors.selectedDatapoints);
  const triggers = useSelector(selectors.triggers);
  const targetKeys = useSelector(selectors.targetKeys);
  const period = useSelector(selectors.period);
  const tables = useSelector(selectors.tables);
  const datapointsOpen = useSelector(selectors.datapointsOpen);
  const openDatapoints = () => {
    if (!datapointsOpen) {
      dispatch(actions.openDatapoints(true));
    }
  };
  const [defaultDB, setDB] = useState(null);
  const [isDate, setIsDate] = useState(false);

  useEffect(() => {
    if (databases?.length > 0) setDB(databases[0]?.bdid);
  }, [databases]);

  useEffect(() => {
    if (!period)
      dispatch(
        actions.updatePeriod([
          null,
          moment(`${now.getFullYear()}/${now.getMonth() + 1}`, 'YYYY/MM'),
        ]),
      );
    // eslint-disable-next-line
  }, [period, dispatch]);

  const treeData = tables?.map((table) => ({
    title: `Table ${table.tablenumber}: ${table.itemdescription}`,
    key: table.tablenumber,
    checkable: false,
    children: table.items.row.map((item) => ({
      title: `Row ${item.rownumber}: ${item.itemdescription}`,
      key: `${item.rownumber}t${table.tablenumber}`,
      checkable: false,
      children: table.items.column.map((col) => ({
        title: col.columndescription,
        key: `${col.columnnumber}-${item.rownumber}-${table.tablenumber}`,
      })),
    })),
  }));

  useEffect(() => {
    if (treeData || treeData.length > 0) {
      dispatch(actions.setTreeData(treeData));
    }
  }, [dispatch, treeData]);

  const getChartsHeaders = () => {
    if (period && selectedBanks?.length > 0 && targetKeys?.length > 0) {
      const startDate = period[0];
      const endDate = period[1];
      const selectedTables = [];
      const selectedRows = [];
      const values = getValuesFromKeys(targetKeys);
      const getTriggers = () => {
        if (selectedDatapoints) {
          return { ...SIDE_BAR.intialTrigger, ...selectedDatapoints };
        }
        return {
          TARGET_TABLE: true,
          MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: true,
          PERCENT_OF_ASSETS_REF_TABLE: true,
          MARKET_SHARE_REF: true,
        };
      };
      values?.forEach(({ table, row }) => {
        if (!selectedTables.includes(table)) {
          selectedTables.push(table);
        }
        if (!selectedRows.includes(row)) selectedRows.push(row);
      });

      const data = {
        // eslint-disable-next-line
        banks_id_list: selectedBanks
          ?.map((item) => banks?.filter(({ name }) => name === item).map(({ id }) => id))
          .flat(),
        period: [getFormatedData(startDate), getFormatedData(endDate)],
        tables: selectedTables.map((id) => ({
          table_id: id,
          // eslint-disable-next-line
          rows: selectedRows.map((rowId) => ({
            id: rowId,
            // eslint-disable-next-line
            columns: values
              ?.filter(({ row, table }) => table === id && rowId === row)
              ?.map(({ column }) => column),
          })),
        })),
        trigger: getTriggers(),
      };
      dispatch(actions.getCharts.request(data));
    } else {
      const requierdFields = [
        { key: 'date', value: period },
        { key: 'banks', value: selectedBanks },
        { key: 'tables', value: targetKeys },
      ];
      requierdFields.forEach(({ key, value }) => {
        if (!value || value.length === 0) {
          openNotification({
            type: NOTIFICATIONS_CONFIG.types.warning,
            message: `Please select some ${key}`,
          });
        }
      });
    }
  };

  return (
    <Styled.SideBarWrapper
      onClick={() => {
        if (!isDate) dispatch(actions.openDatapoints(false));
      }}
    >
      <Styled.SideBarInputsWrapper>
        <Styled.SideBarInputHeading>{SIDE_BAR.databaseHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onChange={setDatabase}
          value={selectedDatabases.length !== 0 || !defaultDB ? selectedDatabases : [defaultDB]}
        >
          {databases?.map((item) => (
            <Option key={item.bdid} value={item.bdid}>
              {item.bdid}
            </Option>
          ))}
        </Styled.SideBarSelect>
        <Styled.SideBarInputHeading>{SIDE_BAR.banksHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={addBank}
          onDeselect={removeBank}
          value={selectedBanks}
        >
          {banks &&
            [...banks]
              .sort((a, b) => {
                if (SIDE_BAR.defaultBanks.includes(a.name.toLowerCase())) {
                  return 1;
                }
                if (SIDE_BAR.defaultBanks.includes(b.name.toLowerCase())) {
                  return -1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
              .map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
        </Styled.SideBarSelect>
        <Styled.SideBarInputHeading>{SIDE_BAR.periodHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarDate onChange={setPeriod} value={period} format="YYYY/MM" />
        <Styled.SideBarOperationsWrapper>
          <Styled.SideBarInputHeading>{SIDE_BAR.operationsHeadingText}</Styled.SideBarInputHeading>
          <Styled.SideBarOperationsInput
            onClick={() => {
              dispatch(actions.openOperationsMenu(true));
            }}
          />
        </Styled.SideBarOperationsWrapper>
        <Styled.SideBarInputHeading>{SIDE_BAR.datapointsHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={setDatapoints}
          onDeselect={unsetDatapoints}
          value={triggers}
          open={datapointsOpen}
          onClick={openDatapoints}
          onMouseLeave={() => setIsDate(false)}
          onMouseEnter={() => setIsDate(true)}
        >
          <Option
            key="MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS"
            onMouseLeave={() => setIsDate(false)}
            onMouseEnter={() => setIsDate(true)}
          >
            {SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS}
          </Option>
          <Option
            key="MARKET_SHARE_REF"
            onMouseLeave={() => setIsDate(false)}
            onMouseEnter={() => setIsDate(true)}
          >
            {SIDE_BAR.points.MARKET_SHARE_REF}
          </Option>
          <Option
            key="PERCENT_OF_ASSETS_REF_TABLE"
            onMouseLeave={() => setIsDate(false)}
            onMouseEnter={() => setIsDate(true)}
          >
            {SIDE_BAR.points.PERCENT_OF_ASSETS_REF_TABLE}
          </Option>
          <Option
            key="TARGET_TABLE"
            onMouseLeave={() => setIsDate(false)}
            onMouseEnter={() => setIsDate(true)}
          >
            {SIDE_BAR.points.TARGET_TABLE}
          </Option>
        </Styled.SideBarSelect>
      </Styled.SideBarInputsWrapper>
      <Styled.SideBarControlsWrapper>
        <Styled.ResetButton onClick={clearData}>
          <RedoOutlined />
          {SIDE_BAR.resetButtonText}
        </Styled.ResetButton>
        <Styled.GenerateButton onClick={() => getChartsHeaders()}>
          {SIDE_BAR.generateButtonText}
        </Styled.GenerateButton>
      </Styled.SideBarControlsWrapper>
      <Styled.ShowSelectedDataWrapper>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.banksSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataText>
          {selectedBanks.length > 0 &&
            selectedBanks
              ?.slice()
              ?.sort((a, b) => a - b)
              ?.map((bank) => <p>{bank}</p>)}
        </Styled.ShowSelectedDataText>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.seriesSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataTable
          dataSource={getValuesFromKeys(targetKeys)}
          columns={SIDE_BAR.tableColumns}
          pagination={false}
        />
      </Styled.ShowSelectedDataWrapper>
      {operationsMenuOpen && (
        <Styled.OperationsMenu
          width="calc(100%-100px)"
          heigh="800px"
          footer={null}
          onCancel={() => dispatch(actions.openOperationsMenu(false))}
          visible
        >
          <OperationsWindow
            onChange={selectColumns}
            targetKeys={targetKeys}
            dataSource={treeData}
          />
          <Styled.OperationsControlsWrapper>
            <Styled.ResetTablesButton onClick={() => selectColumns([])}>
              <RedoOutlined />
              {SIDE_BAR.operationsRefreshButtonText}
            </Styled.ResetTablesButton>
            <Styled.AddTablesButton onClick={() => dispatch(actions.openOperationsMenu(false))}>
              {SIDE_BAR.operationsAddButtonText}
            </Styled.AddTablesButton>
          </Styled.OperationsControlsWrapper>
        </Styled.OperationsMenu>
      )}
    </Styled.SideBarWrapper>
  );
};

export default DataSelect;
